
// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
@import "../node_modules/ng-zorro-antd/ng-zorro-antd.less";

@primary-color: #FFBF00;

// Override the primary color
:root {
  --ant-primary-color: #FFBF00; // Replace with your desired color
  --ant-primary-color-hover: #ce9b00; // Optional: Hover state
}

/* Pagination styles */
.ant-pagination {
    .ant-pagination-item {
      background-color: lighten(@primary-color, 10%);
      border-color: @primary-color;
      color: #fff; /* White text for better contrast */
  
      &:hover {
        background-color: darken(@primary-color, 5%);
        border-color: darken(@primary-color, 5%);
        color: #fff;
      }
    }
  
    /* Active page styles */
    .ant-pagination-item-active {
      background-color: @primary-color;
      border-color: @primary-color;
      color: #fff; /* Ensure active page text is readable */
    }
  
    /* Previous and Next buttons */
    .ant-pagination-prev,
    .ant-pagination-next {
      .ant-pagination-item-link {
        background-color: lighten(@primary-color, 10%);
        border-color: @primary-color;
        color: #fff;
  
        &:hover {
          background-color: darken(@primary-color, 5%);
          border-color: darken(@primary-color, 5%);
        }
      }
    }
  }
