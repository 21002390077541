
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$hexadash-primary: mat.define-palette(mat.$indigo-palette);
$hexadash-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$hexadash-warn: mat.define-palette(mat.$red-palette);

/* Main Loading Screen */
.loading-screen {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	background: #f4f4f4;
	position: relative;
  }
  
  .animation-container {
	position: relative;
	width: 200px;
	height: 150px;
	overflow: hidden;
  }
  
  /* Car Parking Scene */
  .parking-scene {
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
  }
  
  .car {
	width: 50px;
	height: 30px;
	background: #007bff;
	border-radius: 5px;
	position: absolute;
	bottom: 0;
	left: -60px;
	animation: drive-to-spot 2s ease-in-out forwards;
  }
  
  .parking-spot {
	width: 60px;
	height: 40px;
	border: 2px dashed #333;
	position: absolute;
	bottom: 0;
	right: 10px;
  }
  
  /* Finance Scene */
  .finance-scene {
	position: absolute;
	width: 100%;
	height: 100%;
	opacity: 0;
	animation: show-finance 1s 1s forwards; /* Starts after parking animation */
	display: flex;
	justify-content: space-around;
	align-items: center;
  }
  
  .finance-icon {
	width: 40px;
	height: 40px;
	background: #007bff;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	animation: bounce 1s infinite;
  }
  
  .finance-icon.calculator {
	background: #6c757d;
  }
  
  .finance-icon.coins {
	background: #ffc107;
  }
  
  .finance-icon.graph {
	background: #28a745;
  }
  
  .finance-icon.document {
	background: #dc3545;
  }
  
  /* Animations */
  @keyframes drive-to-spot {
	0% {
	  left: -60px;
	}
	80% {
	  left: calc(100% - 80px);
	}
	100% {
	  left: calc(100% - 80px);
	  transform: rotateY(15deg);
	}
  }
  
  @keyframes show-finance {
	0% {
	  opacity: 0;
	  transform: scale(0.8);
	}
	100% {
	  opacity: 1;
	  transform: scale(1);
	}
  }
  
  @keyframes bounce {
	0%, 100% {
	  transform: translateY(0);
	}
	50% {
	  transform: translateY(-10px);
	}
  }
  
  
  /* Loading Text */
  .loading-text {
	font-size: 1.2rem;
	margin-top: 20px;
	color: #555;
  }

  
// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$hexadash-theme: mat.define-light-theme((
  color: (
    primary: $hexadash-primary,
    accent: $hexadash-accent,
    warn: $hexadash-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($hexadash-theme);

// Theme variables, must be included before the libraries to allow overriding defaults
@tailwind base;
@tailwind components;


@tailwind utilities;

/* Apply the custom font family globally */
@layer base {
	body {
	  @apply font-sans;
	}
  }

// 3rd party libraries

// Theme customization
@import './assets/scss/app.scss';
@import './styles/fonts.css';

font-sans {
	font-family: 'Poppins', 'Tajawal', sans-serif;
}
html, body { height: 100%;   font-family: 'Poppins', 'Tajawal', sans-serif;}
body { margin: 0;  
	line-height: 1.6;
    background-color: #f8f9fa; /* Light background for improved readability */
    font-family: 'Poppins', 'Tajawal', sans-serif;
}

// Custom styles for Angular Material tabs using Tailwind CSS
::ng-deep .mat-tab-group {
	@apply bg-gray-100; // Apply Tailwind background color
  }
  
  ::ng-deep .mat-tab-label {
	@apply font-bold; 
	@apply text-yellow-700; // Apply Tailwind text color
  }
  
  ::ng-deep .mat-tab-label-active {
	@apply font-bold; 
	@apply text-yellow-900 font-bold; // Apply Tailwind text color and font weight
  }
  
  ::ng-deep .mat-ink-bar {
	@apply bg-yellow-900; // Apply Tailwind background color
  }
  
  ::ng-deep .ant-select-selector {
	border-color: #FFD700 !important; /* Border color set to yellow */
	background-color: #FFF8DC !important; /* Optional: Light yellow background */
  }
  
  ::ng-deep .ant-select-focused .ant-select-selector {
	border-color: #FFC107 !important; /* Slightly darker yellow for focus */
	box-shadow: 0 0 0 2px rgba(255, 215, 0, 0.2) !important; /* Yellow focus effect */
  }
  
  ::ng-deep .ant-select-item-option-selected {
	background-color: #FFECB3 !important; /* Light yellow for selected item background */
	color: #FFD700 !important; /* Yellow text color for selected item */
  }

.iti {
	display: block !important;
	margin-bottom: 20px;
}

.iti .dropdown-menu.country-dropdown {
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	border-color: #c7cace;
	margin-top: -1px;
}

.iti .iti__country-list {
	box-shadow: none;
	font-size: 14px;
	margin-left: 0;
	width: 244px;
	max-height: 170px;
}

.iti__flag-container.open + input {
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}

.iti .search-container input {
	font-size: 14px;
	border-color: #c7cace;
	border-radius: 0;
	padding: 5px 10px;
}

.iti .search-container input:focus {
	outline: none;
}

@media screen and (max-width: 479px) {
	.iti .iti__country-list {
		width: 88.3vw;
	}
}

ngx-intl-tel-input input {
	height: 44px;
	margin-bottom: 20px;
	padding: 10px;
	border-style: solid;
	border-width: 1px;
	border-color: #c7cace;
	border-radius: 4px;
	font-size: 18px;
}

ngx-intl-tel-input.ng-invalid.ng-touched input {
	border: 1px solid #c0392b;
}

ngx-intl-tel-input input:hover {
	box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.24);
}

ngx-intl-tel-input input:focus {
	outline: none !important;
	border-color: #3498db;
	box-shadow: 0 0 0 0 #000;
}

ngx-intl-tel-input input::-webkit-input-placeholder {
	color: #bac2c7;
}

ngx-intl-tel-input input:-ms-input-placeholder {
	color: #bac2c7;
}

ngx-intl-tel-input input::-ms-input-placeholder {
	color: #bac2c7;
}

ngx-intl-tel-input input::placeholder {
	color: #bac2c7;
}

ngx-intl-tel-input input[disabled] {
	background-color: #e5eaf1;
}
